<template>
<Modal v-model="showModal" :mask-closable="false" draggable width="1300px">
  <div slot="header">通信记录查询</div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
  </div>
  <Form :model="filter" :label-width="60" inline>
    <FormItem label="设备ID">
      <Input v-model="filter.id" clearable placeholder="设备ID" style="width: 100px" />
    </FormItem>
    <FormItem label="ICCID">
      <Input v-model="filter.iccid" clearable placeholder="ICCID" style="width: 200px" />
    </FormItem>
    <FormItem label="IMEI">
      <Input v-model="filter.imei" clearable placeholder="IMEI" style="width: 150px" />
    </FormItem>
    <FormItem label="更新时间" :label-width="70">
      <DatePicker type="date" v-model="filter.start" format="yyyy-MM-dd" style="width: 115px"></DatePicker>
    </FormItem>
    <FormItem label="至" :label-width="20">
      <DatePicker type="date" v-model="filter.end" format="yyyy-MM-dd" style="width: 115px"></DatePicker>
    </FormItem>
    <FormItem :label-width="0">
      <Button type="primary" @click="getList" :loading="loading" style="margin-right: 10px">查询</Button>
      <Button type="success" @click="exportList" :loading="loading">导出</Button>
      <!-- <Button type="info" @click="getCount" style="margin-left: 10px" :loading="loading">数量</Button> - {{count}} -->
    </FormItem>
  </Form>
  <!-- <Table border size="small" stripe highlight-row :columns="cols" :height="500" :loading="loading" :data="list"></Table> -->
  <vxe-table empty-text="无数据" border resizable show-overflow show-header-overflow :data="list" :height="500" :row-config="{isHover: true}">
    <vxe-column type="seq" width="60" title="序号" fixed="left"></vxe-column>
    <vxe-column field="id" title="设备ID" header-align="center"></vxe-column>
    <vxe-column field="iccid" title="ICCID" header-align="center" width="250"></vxe-column>
    <vxe-column field="imei" title="IMEI" header-align="center" width="200"></vxe-column>
    <vxe-column field="create" title="创建时间" header-align="center" width="150"></vxe-column>
    <!-- <vxe-column field="update" title="更新时间" header-align="center" width="150"></vxe-column> -->
    <vxe-column field="update" title="更新时间" header-align="center" width="150">
      <template #default="{ row }">
        <span :style="{color: `${row.update == row.create ? '' : 'blue'}`}">{{row.update}}</span>
      </template>
    </vxe-column>
  </vxe-table>
  <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
    :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange">
  </vxe-pager>
  <ModalProtocolResult v-model="showEditModal" :item="editItem" />
</Modal>
</template>
<script>
import { mapState } from 'vuex'
import ModalProtocolResult from './ModalProtocolResult'
export default {
  name: 'ModalTransLogs',
  components: {
    ModalProtocolResult
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('trans', ['connections']),
  },
  data(){
    return {
      pageSizes:[20,100,500,1000,5000],
      showModal: this.value,
      showDebugModal: false,
      loading: false,
      filter: {
        id: '',
        iccid: '',
        imei: '',
        start: '',
        end: '',
        index: 1,
        size: 20,
      },
      list: [],
      total: 0,
      showEditModal: false,
      editItem: {},
    }
  },
  created: function () {
  },
  mounted: function(){
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        let now = new Date();
        now.setDate(now.getDate() + 1);
        this.filter.end = now.todayEnd();
        now.setDate(now.getDate() - 30);
        this.filter.start = now.todayStart();
        this.getList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  destroyed: function(){
    // ws.stop();
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getList(){
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/search/QuerySimcard`, this.filter).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data.list);
          this.total = res.data.count;
        }
      });
    },
    exportList: function(){
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: 'ICCID记录数据.xlsx',
        url: `//${this.domains.trans}/station/search/ExportSimcard`,
        args: this.filter,
      }).then(() => {
        this.exportLoading = false;
      });
    },
    handleRefresh: function(){
    },
    cancel: function(){
      this.showModal = false;
    }
  },
}
</script>
<style>
.show-protocol{
  cursor: pointer;
}
</style>