<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="1000px">
    <div slot="header">调试连接 - {{item.data}} - {{item.code}}</div>
    <Form ref="form" :label-width="80">
      <FormItem label="发送数据">
        <div style="display: flex">
          <Input type="text" v-model="cmd" placeholder="请输入要发送的数据" style="flex:auto;width:10px"></Input>
          <Checkbox v-model="ascii" style="width:110px;margin-left: 10px;border:solid 0 red">ASCII码发送</Checkbox>
          <Button type="primary" :loading="loading" @click="ok" style="margin-left: 0">发送</Button>
          <Button type="error" :loading="loading" @click="clear" style="margin-left: 10px">清除</Button>
          <Button type="info" :loading="loading" @click="config4G" style="margin-left: 10px">配置4G</Button>
        </div>
      </FormItem>
      <FormItem label="4G配置" v-if="show4GConfig">
        <div style="display: flex">
          通道：
          <Select v-model="g4form.channel" style="width:100px;flex:none;margin-right: 10px">
            <Option value="A">A</Option>
            <Option value="B">B</Option>
            <Option value="C">C</Option>
            <Option value="D">D</Option>
          </Select>
          服务器IP：
          <Input type="text" v-model="g4form.server" placeholder="请输入IP" style="width:150px;flex:none;margin: 0 10px"></Input>
          端口：
          <Input type="number" v-model="g4form.port" placeholder="请输入端口" style="width:100px;flex:none;"></Input>
          <Button type="primary" :loading="loading" @click="startConfig" style="margin-left: 10px">确认</Button>
        </div>
      </FormItem>
      <FormItem :label-width="30">
        <div ref="messageContent" class="message-content">
          <div v-for="(item, idx) in trans" :key="idx">
            {{new Date(item.ts).format('HH:mm:ss.fff')}} - <span class="protocol-result" :style="{color: `${item.send ? 'blue' : 'green'}`}" @click="showPrococol(item)">{{item.send ? '发送':'接收'}}</span> - {{item.bytes}}
          </div>
          <div style="margin-bottom: 50px;"></div>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <ModalProtocolResult v-model="showEditModal" :item="editItem" />
    </div>
  </Modal>
</template>
<script>
import {mapState} from 'vuex'
import ModalProtocolResult from './ModalProtocolResult'
export default {
  name: 'ModalDebug',
  components:{
    ModalProtocolResult
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      cmd: '',
      ascii: false,
      show4GConfig: false,
      g4form: {
        channel: 'B',
        server: '211.162.119.217',
        port: '44440',
      },
      showEditModal: false,
      editItem: {},
    }
  },
  watch: {
    msgs(){
      this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight;
    },
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.show4GConfig = false;
        this.$store.commit('cmd/enableMonitorDeviceByConn', this.item.data);
      }else{
        this.$store.commit('cmd/disableMonitorDeviceByConn');
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('cmd', ['trans', 'monitorDeviceId']),
  },
  mounted: function(){
  },
  methods: {
    showPrococol: function(params){
      // console.log('show protocol 1', params)
      if(params.send)return;
      this.editItem = {id: params.code, content: params.bytes };
      this.showEditModal = true;
    },
    str2accii16: function(str){
      let result = '';
      for(let i = 0; i < str.length; i++){
        result += str.charCodeAt(i).toString(16) + " ";
      }
      result += "0d 0a";
      return result;
    },
    startConfig: function(){
      let start = 'usr.cnAT',
      cfg = `usr.cnAT+SOCK${this.g4form.channel}=TCP,${this.g4form.server},${this.g4form.port}`,
      over = `usr.cnAT+SOCK${this.g4form.channel}EN=ON`,
      reset = 'usr.cnAT+REBOOT';
      start = this.str2accii16(start);
      cfg = this.str2accii16(cfg);
      over = this.str2accii16(over);
      reset = this.str2accii16(reset);
      let cmds = [start, cfg, over, reset];
      for(let i = 0; i < cmds.length; i++){
        setTimeout(() => {
          this.$axios.post(`//${this.domains.trans}/station/search/trans`, {key: this.item.data, cmd: cmds[i], type: 'byte'})
          // this.$store.dispatch('trans/sendCommandData', {key: this.item.data, cmd: cmds[i], type: 'byte'});
        }, i * 1500);
      }
      this.show4GConfig = false;
    },
    config4G: function(){
      this.show4GConfig = !this.show4GConfig;
    },
    clear: function(){
      this.$store.commit('cmd/clearTransData');
    },
    ok: async function () {
      if(!this.cmd){
        this.$Message.warning('请输入指令');
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/command/HandData`, {conn: this.item.data, cmd: this.cmd, ascii: this.ascii}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('指令发送成功');
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.message-content{
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}
.protocol-result{
  cursor: pointer;
}
</style>